import dot from './dot.png';

const PinkTextHeadline = ({ text }) => {
    return (
        <div style={styles.div}>
            <img src={dot} style={styles.dot}/>
            <text style={styles.text}>{text}</text>
        </div>
    );
};

const styles = {
    div: {
        flexDirection: 'column',
    },
    text: {
        marginTop: '50px',
        marginLeft: '15px',
        fontStyle: 'italic',
        textAlign: 'start',
        fontSize: '1rem',
        color: '#FF007A'
    },
    dot: {
        width: '12px',
        height: '12px'
    },
};

export default PinkTextHeadline;