import './App.css';

import logo from './logo.svg';
import search from './search.svg';
import comedogenic from './comedogenic.png';

import {Link} from "react-router-dom";
import React, {useState} from "react";
import axios from 'axios';

import Header from "./Header";
import PinkTextHeadline from "./PinkTextHeadline";
import IngredientList from "./IngredientList";
import PinkTextList from "./PinkTextList";
import DescriptionText from "./DescriptionText";


const menuItems = [
    {name: "home", image: comedogenic},
    //{name: "about", image: comedogenic},
    //{name: "contact", image: comedogenic}
];

const Home = () => {
    const [items, setItems] = useState([]);
    const [query, setQuery] = useState('');
    const formattedIngredients = formatIngredients(query);

    let ingredients = []

    const handleChange = (event) => {
        setQuery(event.target.value);
    };
    const handleButtonClick = () => {
        ingredients = formattedIngredients
        console.log(ingredients.length)
        searchField();
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleButtonClick();
        }
    };
    const searchField = async () => {
        try {
            const response = await axios.post('https://skiin.site/api/data', ingredients, {
                headers: {'Content-Type': 'application/json'}
            });
            if (Array.isArray(response.data)) {
                setItems([]);
                response.data.forEach((item, index) => {
                    console.log(`Item ${index}: ${item}`);
                    addIngredient(item)
                });
            } else {
                console.error('The response is not an array:', response);
            }
        } catch (error) {
            if (error.response) {
                console.error('Server responded with:', error.response.status, error.response.data);
            }
        }
    };
    const addIngredient = (ingredient) => {
        if (!ingredient) return; // Guard clause for empty text
        setItems((prevItems) => [...prevItems,
            {
                id: prevItems.length + 1,
                name: ingredient.name,
                category: ingredient.category,
                comrating: ingredient.comrating,
                cas: ingredient.cas,
                ec: ingredient.ec,
                source: ingredient.source,
                image: comedogenic
            }]);
        setQuery("");
    };

    return (
        <div className="App">

            <div>
                <Header logo={logo} title="SKIIN"/>
                <hr style={styles.line}/>

                <div style={styles.page}>

                    <div>
                        <nav style={styles.menuContainer}>
                            {menuItems.map((item, index) => (
                                <Link key={index} to={`/${item.name.toLowerCase()}`} style={styles.menuItem}>
                                    {/* Render the image */}
                                    <img src={item.image} alt={item.name} style={styles.menuImage}/>
                                    {/* Render the name of the menu item */}
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                    </div>

                    <div style={styles.searchSide}>
                        <PinkTextHeadline text="Analyse ingredients"></PinkTextHeadline>
                        <div>
                            <input className="centered"
                                   type="text"
                                   value={query}
                                   onChange={handleChange}
                                   onKeyDown={handleKeyDown}
                                   placeholder="Add ingredients..."
                                   style={styles.input}/>
                            <img src={search} alt="Search" onClick={handleButtonClick} style={styles.runButton}/>
                        </div>

                        <PinkTextList items={items}></PinkTextList>
                        <IngredientList items={items}/>
                    </div>

                    <div style={styles.aboutSide}>
                        <DescriptionText></DescriptionText>
                    </div>
                </div>
            </div>
        </div>
    );
}

function formatIngredients(ingredientsString) {
    const ingredientsArray = ingredientsString.split(/[(),/]+/).filter(item => item !== "");
    return ingredientsArray.map(ingredient => {
        const mappedIng = ingredient.trim();
        console.log(mappedIng);
        return mappedIng;
    });
}

const styles = {
    page: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
    },
    searchSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: '#C9F1F8',
    },
    aboutSide: {
        width: '100%',
        height: '100%',
        background: '#DDFBFF',
    },
    line: {
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0 0px',
    },
    input: {
        width: '80%',
        padding: '25px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '15px',
        outline: 'none',
        color: '#5ac8de'
    },
    runButton: {
        width: '50px',
        height: '50px',
        background: '#fff',
        cursor: 'pointer',
        marginBottom: '-20px',
        marginLeft: '-62px'
    },
    menuContainer: {
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginLeft: 'auto',
    },
    menuItem: {
        width: '200px',
        height: '100px',
        color: '#5ac8de',
        display: 'flex',        // Use flexbox to align text and image horizontally
        alignItems: 'center',   // Vertically center the content
        background: '#C9F1F8',
        marginLeft: '50px',
        borderBottomLeftRadius: '100px',
        borderTopLeftRadius: '100px',
    },
    menuImage: {
        width: '100px',
        height: '100px',
        marginRight: '25px',    // Space between image and text
    }
}

export default Home;
