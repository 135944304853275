import PinkTextAbout from "./PinkTextAbout";
import line from './line.png';

const DescriptionText = () => {
    return (
        <div style={styles.div}>
            <img src={line} style={styles.line}/>
            <div style={styles.text}>
                <PinkTextAbout text="What this tool does"></PinkTextAbout>
                <p style={styles.greenText}>This tool analyses cosmetic ingredients and gives information on which ones
                    from the provided list clog pores.</p>
                <PinkTextAbout text="Whom is it for"></PinkTextAbout>
                <p style={styles.greenText}>Its main audience are people with sensitive skin, prone to acne.</p>
                <PinkTextAbout text="How to use it"></PinkTextAbout>
                <p style={styles.greenText}>Pass a list of the skin care product ingredients you aim to buy and search
                    our database. If any ingredient from the list is comedogenic, the tool will show how much.</p>
                <PinkTextAbout text="Future updates"></PinkTextAbout>
                <p style={styles.greenText}>Soon we will update the website with information about this tool's
                    methodology of work and new features.</p>
            </div>
        </div>
    );
};

const styles = {
    div: {
        display: 'grid',
        gridAutoFlow: 'column',
    },
    text: {
        marginLeft: '-6.5px',
        marginTop: '75px',
        marginRight: '25px',
        textAlign: 'start',
        fontSize: '1rem',
    },
    greenText: {
        marginTop: '5px',
        marginBottom: '25px',
        marginLeft: '25px',
        color: '#5ac8de'
    },
    line: {
        marginTop: '80px',
        width: '1px',
        height: '63%',
        marginLeft: '50px'
    }
};

export default DescriptionText;