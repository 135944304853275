import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import React from "react";

const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
        </Routes>
    </Router>
);

export default App;