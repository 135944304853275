const PinkTextList = ({ items }) => {
    return (
        <div>
            {items.length > 0 ? (
                <p style={styles.text}>{"Analysed ingredients"}</p>
            ) : (
                <p style={styles.text}></p>
            )}
        </div>
    );
};

    const styles = {
        text: {
        display: 'flex',
        marginTop: '30px',
        marginLeft: '50px',
        fontStyle: 'italic',
        textAlign: 'start',
        fontSize: '1rem',
        color: '#FF007A'
    },
};

export default PinkTextList;